#grid-container{
    display: grid;
    grid-template-columns: 65% 33%;
    column-gap: 10px;
    /* position: relative; */
}

.grid-item, #sub1, #sub2{
    overflow: hidden;
}
#sub1{
    margin-bottom:10px ;
}
#main-blog,#sub1, #sub2{
    position: relative;
}
#grid-container img{
    width: 100%;
    height: 100%;
    transition: all 1s;
    opacity: .7;
}
.fade{
    background: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
}

#grid-container img:hover{
    scale: 1.2;
}

.text{
    text-align: left;
    font-weight: bold;
    /* z-index: 1; */
    position: absolute;
    bottom : 7%;
    left: 3%;
    color: white;
}
.cat{
    color: #ec1c45;
    font-size: 16px;
   
}

.tag{
    font-size: 1.5vw;
}

.auth-name{
    font-weight: 400;
}

#sub1 .tag, #sub2 .tag{
    font-size: 1.3vw;
}