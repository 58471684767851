.setting-cont {
    display: flex;
}

.setting {
    flex: 1;
    text-align: left;
    margin-right: 10px;
}

.setting-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.setting-delete {
    color: red;
    cursor: pointer;
}

.profile-pic img {
    width: 60px;
    height: 60px;
    margin-top: 20px;
    border-radius: 20%;
}

#profile-pic {
    display: none;
}

.profile-pic label {
    margin-left: 10px;
    position: relative;
    top: -20px;
    cursor: pointer;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.form-group label {
    margin-top: 10px;
    font-weight: 500;
}

.form-group input {
    width: 30%;
    margin-top: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid;
}

.form-group input:focus {
    outline: none;
}

form input[type="text"] {
    text-transform: lowercase;
}

#submit-profile {
    margin-top: 20px;
    padding: 8px;
    font-size: 15px;
    background-color: teal;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}