.social-media {
  width: 15%;
  margin: 0px 0 0 20px;
}

#cat-heading {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(204, 189, 189);
}

.categorie {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(204, 189, 189);
}

.categorie h4 {
  font-size: 10px;
}

.categorie p {
  font-size: 11px;
}
