.single-post{
    text-align: justify;
    position: relative;
}
.single-img-cont{
    width: 100%;
    height: 420px;
    position: relative;
}

.single-img{
    width: 100%;
    height: 100%;
}

.single-text{
    color: white;
    background: rgba(23, 23, 23, 0.363);
    position: absolute;
    padding: 10px 10px 10px 30px;
    border-radius: 10px;
    top: 300px;
    left: 10px;
    font-weight: 700;
}
.title-icon{
    display: flex;
    justify-content: space-between;
}

.single-icon{
    padding: 15px;
}
.single-icon i{
    margin-left: 20px;
    cursor: pointer;
}